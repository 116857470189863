import { BaseMapElement } from "./_BaseMapElement"
import { ICoord, TAnyArgs } from "@types"

export abstract class BaseMapElementFactory<T extends { coord: ICoord }, U extends BaseMapElement<T>> {
  protected elements: Record<string, U> = {}

  protected isVisible = true

  constructor(protected dataEntityList: T[]) {}

  abstract createElements: (...args: TAnyArgs) => void

  calcPositions = (overlayProjection: google.maps.MapCanvasProjection): void => {
    Object.values(this.elements).forEach((el) => el.calcPosition(overlayProjection))
  }

  dispose = (): void => {
    Object.values(this.elements).forEach((el) => el.dispose())
  }

  setVisible = (isVisible: boolean): void => {
    if (isVisible === this.isVisible) return

    Object.values(this.elements).forEach((el) => el.setVisible(isVisible))
    this.isVisible = isVisible
  }
}
