import { ICoord, TAnyArgs } from "@types"

export abstract class BaseMapElement<T extends { coord: ICoord }> {
  protected _element: HTMLDivElement
  protected isVisible = true

  constructor(protected _dataEntity: T) {
    this._element = this.createElement()
  }

  get element(): HTMLDivElement {
    return this._element
  }

  get dataEntity(): T {
    return this._dataEntity
  }

  set dataEntity(newData: T | Partial<T>) {
    this._dataEntity = { ...this._dataEntity, ...newData }
  }

  protected abstract createElement(...args: TAnyArgs): HTMLDivElement

  mount = (parent: Element, preventCb: (element: Element) => void): void => {
    parent.appendChild(this._element)
    preventCb(this._element)
  }

  calcPosition = (overlayProjection: google.maps.MapCanvasProjection): void => {
    const pixelPos = overlayProjection.fromLatLngToDivPixel(this._dataEntity.coord)!

    this._element.style.left = Math.round(pixelPos.x - parseInt(this._element.style.width, 10) / 2) + "px"
    this._element.style.top = Math.round(pixelPos.y - parseInt(this._element.style.height, 10) / 2) + "px"
  }

  setVisible = (isVisible: boolean): void => {
    if (isVisible === this.isVisible) return

    this._element.style.display = isVisible ? "flex" : "none"
    this.isVisible = isVisible
  }

  dispose = (): void => {
    ;(this._element.parentNode as HTMLElement).removeChild(this._element)
  }
}
