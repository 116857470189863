import { FilterController } from "components/map/fragments"
import { BaseMapElementFactory } from "../../base"
import { ELayers, IBuildingsMarker } from "@types"
import { BuildingsMarker } from "./_BuildingsMarker"

export class BuildingsMarkerFactory extends BaseMapElementFactory<IBuildingsMarker, BuildingsMarker> {
  constructor(dataEntityList: IBuildingsMarker[], private filterController: FilterController) {
    super(dataEntityList)
  }

  createElements = (parent: Element, preventCb: (element: Element) => void): void => {
    this.dataEntityList.forEach(this.createMarker)
    this.mountElements(parent, preventCb)
    this._filter()
  }

  private createMarker = (marker: IBuildingsMarker): void => {
    this.elements[marker.id] = new BuildingsMarker(marker)
  }

  mountElements = (parent: Element, preventCb: (element: Element) => void): void => {
    this.dataEntityList.forEach((item) => {
      this.elements[item.id].mount(parent, preventCb)
    })
  }

  override calcPositions = (overlayProjection: google.maps.MapCanvasProjection): void => {
    this.dataEntityList.forEach((item) => {
      this.elements[item.id].calcPosition(overlayProjection)
    })
  }

  filter = (): void => this._filter()

  private _filter(): void {
    const isVisible = this.filterController.hasPassedFilters(ELayers.BUILDINGS)
    this.setVisible(isVisible)
  }

  reset = (newData: IBuildingsMarker[]): void => {
    this.dispose()
    this.isVisible = true

    this.dataEntityList = newData
    this.elements = {}
  }
}
