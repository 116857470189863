import { BaseMapElement } from "../../base"
import { IBuildingsMarker } from "@types"
import { getBuildingIcon } from "./_getBuildingIcon"
import { createCustomElement } from "../../../_utils"

export class BuildingsMarker extends BaseMapElement<IBuildingsMarker> {
  protected createElement(): HTMLDivElement {
    const element = createCustomElement("div", {
      width: "40px",
      height: "40px",
      position: "absolute",
    }) as HTMLDivElement

    element.innerHTML = getBuildingIcon(this.dataEntity.buildings)

    return element
  }
}
